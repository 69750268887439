<template>
  <div class="login-container">
    <div class="title-container">
      <h3 class="title"></h3>
    </div>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <div class="tab-box-old">象山县未来社区智慧服务平台</div>
      <!-- <div class="tab-box">
        <div
          :class="['left-box', { activeMethod: loginMethods == 1 }]"
          @click="changeLoginMethod(1)"
        >
          服务平台账号登录
          <div class="active-line" v-if="loginMethods == 1"></div>
        </div>

        <div
          :class="['right-box', { activeMethod: loginMethods == 2 }]"
          @click="changeLoginMethod(2)"
        >
          浙里办账号登录
          <div class="active-line" v-if="loginMethods == 2"></div>
        </div>
      </div> -->
      <el-form-item prop="username">
        <!-- <span class="svg-container">
          <i class="el-icon-user-solid"></i>
        </span> -->
        <v-input
          v-model="loginForm.username"
          placeholder="请输入您的账号"
          name="username"
          type="text"
          tabindex="1"
          autocomplete="on"
          :maxlength="50"
        />
      </el-form-item>

      <el-form-item prop="password" class="te">
        <!-- <span class="svg-container">
          <i class="el-icon-lock"></i>
        </span> -->
        <v-input
          v-model="loginForm.password"
          :type="isShowPwd ? 'text' : 'password'"
          placeholder="请输入您的密码"
          name="password"
          tabindex="2"
          autocomplete="on"
          :maxlength="50"
          @keyup.enter.native="handleLogin"
        />
        <span class="show-pwd" @click="isShowPwd = !isShowPwd">
          <i :class="isShowPwd ? 'el-icon-view' : 'el-icon-more'"></i>
        </span>
      </el-form-item>
      <div class="forGetPassWord">
        <span
          :class="[{ other: loginMethods == 2 }]"
          @click="showForGetPassWordDialog()"
          >忘记密码</span
        >
      </div>

      <div
        class="btnBox"
        style="width: 100%; padding: 0 43px; box-sizing: border-box"
      >
        <v-button
          :loading="loading"
          type="primary"
          oStyle="width: 100%;height:53px;font-size:27px;color:#ffffff;font-weight: 600;"
          @click.native.prevent="handleLogin"
          text="登录"
        ></v-button>
      </div>
    </el-form>
    <el-dialog width="30%" :visible.sync="showDialog" :show-close="false">
      <div class="forGetPassWord-form">
        <div class="title">忘记密码</div>
        <div class="stepLine">
          <div
            :class="['step', { active: activeIndex === 1 || activeIndex > 1 }]"
          >
            1
          </div>
          <div class="line"></div>
          <div
            :class="['step', { active: activeIndex === 2 || activeIndex > 2 }]"
          >
            2
          </div>
          <div class="line"></div>
          <div
            :class="['step', { active: activeIndex === 3 || activeIndex > 3 }]"
          >
            3
          </div>
        </div>
        <div class="strLine">
          <div
            :class="['name', { active: activeIndex === 1 || activeIndex > 1 }]"
          >
            填写账号信息
          </div>
          <div
            :class="['name', { active: activeIndex === 2 || activeIndex > 2 }]"
          >
            获取验证码
          </div>
          <div
            :class="['name', { active: activeIndex === 3 || activeIndex > 3 }]"
          >
            设置新密码
          </div>
        </div>
        <!-- 填写账号信息 -->
        <div class="step1-box" v-if="activeIndex === 1">
          <div class="inputBox">
            <input
              type="text"
              v-model="loginAccount"
              placeholder="输入登录账号"
            />
          </div>
          <div class="inputBox">
            <input
              type="text"
              v-model="registeredMobileNumber"
              placeholder="输入注册手机号"
            />
          </div>
          <div class="nextStep" @click="nextStep(2)">下一步</div>
        </div>
        <!-- 获取验证码 -->
        <div class="step2-box" v-else-if="activeIndex === 2">
          <div class="inputBox">
            <input
              type="text"
              v-model="phoneNum"
              disabled
              style="background-color: transparent"
            />
          </div>
          <div class="getCodeInput">
            <input
              type="text"
              v-model="SMSCode"
              placeholder="输入6位短信验证码"
            />
            <div
              :class="['getCodeStr', { disabled: !reSend }]"
              @click="getCode"
            >
              {{ txt }}
            </div>
          </div>
          <div class="previousStep" @click="previousStep(1)">上一步</div>
          <div class="nextStep" @click="nextStep(3)">下一步</div>
        </div>
        <!-- 设置新密码 -->
        <div class="step3-box" v-else-if="activeIndex === 3">
          <div class="inputBox">
            <input
              type="password"
              v-model="newPassWord"
              placeholder="新密码"
              @blur="newPassWordBlur"
            />
            <span class="passwordInfo">{{ passwordInfo }}</span>
          </div>
          <div class="inputBox">
            <input
              type="password"
              v-model="newPassWordAgain"
              placeholder="再次输入新密码"
              @blur="newPassWordAgainBlur"
            />
            <span class="newPassWordAgainInfo">{{ newPassWordAgainInfo }}</span>
          </div>
          <div class="previousStep" @click="previousStep(2)">上一步</div>
          <div class="submit" @click="submit(1)">完成修改</div>
        </div>
      </div>
    </el-dialog>
    <div class="login-b">
      <a href="https://beian.miit.gov.cn">浙ICP备2024117381号-1</a>
      <div>
        <a
          href="https://beian.mps.gov.cn/#/query/websearch?code=33022502000400"
          rel="noreferrer"
          target="_blank"
          >浙公网安备33022502000400号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import { validUsername, _localStorage } from "@/utils/utils";
import { AESEcbEncrypt } from "@/utils/common";
import {
  loginUrl,
  newLoginUrl,
  zlbLoginUrl,
  getVerificationCodeUrl,
  checkVerificationCodeUrl,
  changeLoginPwdUrl,
} from "./api";
export default {
  name: "Login",
  components: {},
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error("用户名不能为空"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error("密码不能为空"));
      } else {
        callback();
      }
    };
    return {
      loginMethods: 1,
      activeIndex: 1,
      loginAccount: "",
      registeredMobileNumber: "",
      phoneNum: null,
      SMSCode: null,
      newPassWord: null,
      passwordInfo: "",
      newPassWordAgainInfo: "",
      newPassWordAgain: null,
      reSend: true,
      txt: "获取验证码",
      timer: null,
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      isShowPwd: false,
      // passwordType: "password",
      loading: false,
      showDialog: false,
      otherQuery: {},
      menu: [],
      url: "",
    };
  },
  created() {
    // 进入该页面则清除所有缓存
    sessionStorage.clear();
    localStorage.clear();
  },
  mounted() {},
  destroyed() {
    clearTimeout(this.timer);
  },
  methods: {
    newPassWordAgainBlur() {
      if (this.newPassWordAgain != this.newPassWord) {
        this.newPassWordAgainInfo = "两次密码必须一致";
      } else {
        this.newPassWordAgainInfo = "";
      }
    },
    newPassWordBlur() {
      if (
        !this.newPassWord.match(
          /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/
        )
      ) {
        this.passwordInfo = "需要至少8位包含英文、字符、数字的密码";
      } else {
        this.passwordInfo = "";
      }
    },
    showForGetPassWordDialog() {
      if (this.loginMethods == 1) {
        this.showDialog = true;
      }
    },
    changeLoginMethod(num) {
      if (this.loginMethods == num) {
        return;
      } else {
        this.loginMethods = num;
        this.loginForm.username = null;
        this.loginForm.password = null;
      }
    },
    getCode() {
      if (this.reSend) {
        this.reSend = false;
        let n = 59;
        let params = {
          loginName: this.loginAccount,
          mobileNum: this.registeredMobileNumber,
        };
        this.$axios.post(`${getVerificationCodeUrl}`, params).then((res) => {
          if (res.code == 200) {
            // this.SMSCode = res.msg;
            this.txt = `${n + 1}秒可重发`;
            this.$message.success("发送成功，请注意查收短信~");
            let time = setInterval(() => {
              this.txt = n + "秒可重发";
              n--;
              if (n < 0) {
                this.txt = "获取验证码";
                this.reSend = true;
                clearInterval(time);
                console.log(123);
              }
            }, 1000);
          } else {
            this.reSend = true;
          }
        });
      }
    },
    nextStep(num) {
      if (num === 2) {
        if (!this.loginAccount || !this.registeredMobileNumber) {
          this.$message.error("登录账号/注册手机号不能为空！");
          return;
        }
        this.phoneNum = "+86" + this.registeredMobileNumber;
        this.activeIndex = num;
      } else if (num === 3) {
        if (!this.SMSCode) {
          this.$message.error("验证码不能为空！");
          return;
        }
        let params = {
          code: this.SMSCode,
          mobileNum: this.registeredMobileNumber,
        };
        this.$axios
          .get(`${checkVerificationCodeUrl}`, { params: params })
          .then((res) => {
            if (res.code === 200) {
              this.activeIndex = num;
            }
          });
      }
    },
    previousStep(num) {
      this.activeIndex = num;
    },
    submit(num) {
      if (!this.newPassWord || !this.newPassWordAgain) {
        this.$message.error("新密码不能为空！");
        return;
      }
      if (this.passwordInfo) {
        this.$message.error("请按照要求填写密码！");
        return;
      }
      if (this.newPassWordAgainInfo) {
        this.$message.error("密码不一致请重新确认！");
        return;
      }
      if (this.newPassWord != this.newPassWordAgain) {
        this.$message.error("密码不一致请重新确认！");
        return;
      }
      let params = {
        loginName: this.loginAccount,
        mobileNum: this.registeredMobileNumber,
        loginPwd: this.newPassWord,
      };
      this.$axios.post(`${changeLoginPwdUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$message.success("修改密码成功");
          this.activeIndex = num;
          this.loginAccount = null;
          this.registeredMobileNumber = null;
          this.SMSCode = null;
          this.showDialog = false;
        }
      });
    },
    // findUrl(oList) {
    //   console.log(oList, 4567);
    //   if (oList[0] && oList[0].url) {
    //     this.url = oList[0].url;
    //     return "";
    //   }else {
    //     console.log(oList[0].children,4567);
    //     this.findUrl(oList[0].children);
    //   }
    // },
    // async getMenu() {
    //   let params = {
    //     roleId: this.$store.state.app.userInfo.roleId,
    //     userId: this.$store.state.app.userInfo.userId,
    //   };
    //   let res = await this.$axios.post(
    //     `/gateway/blade-system/menu/routes`,
    //     params
    //   );
    //   if (res.code === 200) {
    //     this.menu = res.data;
    //   }
    // },
    setAESCbcEncrypt(text) {
      const cbcPkcs7Key = "H@M7e4o%dUb@JTwx";
      return CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(cbcPkcs7Key), {
        iv: CryptoJS.enc.Utf8.parse(cbcPkcs7Key),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
    },
    async toLogin() {
      const formData = new FormData();
      // formData.append("account", this.loginForm.username);
      // formData.append("password", this.loginForm.password);
      formData.append(
        "account",
        this.setAESCbcEncrypt(this.loginForm.username)
      );
      formData.append(
        "password",
        this.setAESCbcEncrypt(this.loginForm.password)
      );
      formData.append(
        "grantType",
        this.loginMethods == 1 ? "password" : "zlbPassword"
      );
      let url = this.loginMethods == 1 ? newLoginUrl : zlbLoginUrl;
      let res = await this.$axios.post(`${url}`, formData, {
        headers: {
          "User-Type": "web",
        },
      });
      console.log(res, "++++", "888");
      if (res.code === 200) {
        let userInfo = res.data;
        _localStorage.setItem("userInfo", JSON.stringify(userInfo));
        this.$store.commit("app/setUserInfo", userInfo);
        console.log(this.$store.state.app.userInfo, 976);
        // await this.getMenu();
        // this.findUrl(this.menu);
        const sign = encodeURIComponent(
          AESEcbEncrypt(`${this.loginForm.username} ${this.loginForm.password}`)
        );
        sessionStorage.setItem("sign", sign);
        console.log(this.url, "++++", "999999999999999999");
        this.$router.replace({
          name: "guide",
        });
      }
    },
    // showPwd() {
    //   if (this.passwordType === "password") {
    //     this.passwordType = "";
    //   } else {
    //     this.passwordType = "password";
    //   }
    // },
    async handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          await this.toLogin();
          this.loading = false;
          // 将用户信息记录到本地存储中
          // sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less">
@bg: #283443;
@light_gray: #fff;
@cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: @cursor)) {
  .login-container .el-input input {
    color: @cursor;
  }
}

/* reset element-ui css */
.login-container {
  .v-input {
    flex: 1;
  }
  .el-input {
    width: 100% !important;
    display: inline-block;
    height: 52px;
    // width: 85%;

    input {
      border: 0px;
      border-radius: 0px;
      padding: 18px 20px;
      height: 52px;
      background: transparent;
      -webkit-appearance: none;
      // color: @light_gray;
      color: rgba(0, 0, 0, 0.85);
      caret-color: rgba(0, 0, 0, 0.85);
      font-size: 22px;

      &:-webkit-autofill {
        // box-shadow: 0 0 0px 1000px @bg inset !important;
        box-shadow: 0 0 0px 1000px @cursor inset !important;
        // -webkit-text-fill-color: @cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(0, 0, 0, 0.25);
    // background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: #454545;
    margin-bottom: 59px;
  }
  .te {
    margin-bottom: 30px;
  }
  .el-form-item__content {
    width: 100%;
    // line-height: 52px;
    display: flex;
  }
  .v-button {
    width: 100%;
  }
}
</style>

<style lang="less" scoped>
@bg: #2d3a4b;
@dark_gray: #889aa4;
@light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  // background-color: @bg;
  // overflow: hidden;
  background-image: url("./img/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  .login-b {
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    a {
      color: #666;
    }
  }
  .title-container {
    position: absolute;
    top: 41px;
    left: 141px;
    .title {
      font-size: 40px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: bold;
    }
  }
  .forGetPassWord-form {
    padding: 0 65px 50px;
    box-sizing: border-box;
    .step1-box,
    .step2-box,
    .step3-box {
      .previousStep {
        margin-top: 84px;
        width: 100%;
        height: 60px;
        border-radius: 8px;
        text-align: center;
        line-height: 60px;
        font-size: 20px;
        font-weight: 600;
        color: #066ce8;
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid #066ce8;
        margin-bottom: -58px;
      }
      .nextStep,
      .submit {
        margin-top: 84px;
        width: 100%;
        height: 60px;
        background: #066ce8;
        border-radius: 8px;
        text-align: center;
        line-height: 60px;
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        cursor: pointer;
      }
      .getCodeInput {
        height: 75px;
        border-bottom: 1px solid #d5d5d5;
        display: flex;
        align-items: center;
        .disabled {
          color: rgba(0, 0, 0, 0.25) !important;
        }
        .getCodeStr {
          font-size: 18px;
          color: #126be2;
          cursor: pointer;
        }
        input::placeholder {
          font-size: 18px;
          font-weight: 500;
          color: #8590a6;
        }
        input {
          flex: 1;
          height: 100%;
          font-size: 18px;
          font-weight: 500;
          color: #8590a6;
        }
      }
      .inputBox {
        height: 75px;
        border-bottom: 1px solid #d5d5d5;
        position: relative;
        .passwordInfo,
        .newPassWordAgainInfo {
          position: absolute;
          left: 0;
          bottom: -20px;
          color: red;
          z-index: 10;
        }
        input::placeholder {
          font-size: 18px;
          font-weight: 500;
          color: #8590a6;
        }
        input {
          width: 100%;
          height: 100%;
          font-size: 18px;
          font-weight: 500;
          color: #8590a6;
        }
      }
    }
    .strLine {
      padding-top: 24px;
      padding-bottom: 50px;
      font-size: 16px;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.25);
      justify-content: space-between;
      box-sizing: border-box;
      .active {
        color: #8590a6 !important;
      }
    }
    .stepLine {
      display: flex;
      align-items: center;
      margin-top: 46px;
      box-sizing: border-box;
      padding: 0 25px 0 33px;
      .step {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #d6d6d6;
        border-radius: 50%;
        font-size: 16px;
        color: #ffffff;
      }
      .active {
        background: #066ce8 !important;
      }
      .line {
        flex: 1;
        height: 1px;
        margin: 0 10px;
        box-sizing: border-box;
        background-color: #e5e5e5;
      }
    }
    .title {
      font-size: 20px;
      text-align: center;
      color: #000000;
    }
  }
  .forGetPassWord {
    font-size: 16px;
    color: #617785;
    text-align: right;
    padding-bottom: 20px;
    span {
      cursor: pointer;
    }
    .other {
      color: transparent !important;
    }
  }

  .login-form {
    position: absolute;
    top: 50%;
    right: 110px;
    transform: translateY(-50%);
    width: 849px;
    box-sizing: border-box;
    max-width: 100%;
    padding: 77px 117px 85px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(196, 196, 196, 0.5);
    border-radius: 25px;
    .tab-box-old {
      font-size: 44px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0 auto 68px auto;
      text-align: center;
      font-weight: 700;
    }
    .tab-box {
      width: 100%;
      height: 100px;
      border-bottom: 7px solid #ecf2f7;
      margin-bottom: 100px;
      font-size: 30px;
      color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      line-height: 100px;
      box-sizing: border-box;
      border-radius: 3px;
      .left-box,
      .right-box {
        white-space: nowrap;
        margin-right: 80px;
        height: 100%;
        cursor: pointer;
        position: relative;
        .active-line {
          width: 100%;
          height: 7px;
          background-color: #3680ff;
          position: absolute;
          border-radius: 3px;
          bottom: -7px;
          left: 0;
        }
      }
      .activeMethod {
        font-size: 35px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: @dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: @dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }
}
</style>
